import uuid from "uuid/v4";
import {
  ADD_TO_CART,
  DECREASE_QUANTITY,
  DELETE_FROM_CART,
  DELETE_ALL_FROM_CART
} from "../actions/cartActions";

const initState = [];

const cartReducer = (state = initState, action) => {
  const cartItems = state,
    product = action.payload;

  if (action.type === ADD_TO_CART) {

    const cartItem = cartItems.filter(item => item.Productoid === product.Productoid)[0];
    if (cartItem === undefined) {
      return [
        ...cartItems,
        {
          ...product,
          quantity: product.quantity ? product.quantity : 1,
          cartItemId: uuid()
        }
      ];
    } else {
      // console.log(product);

      return cartItems.map(item =>
        item.cartItemId === cartItem.cartItemId
          ? {
            ...item,
            quantity: product.quantity
              ? product.quantity
              : item.quantity + 1
          }
          : item
      );
    }


  }

  if (action.type === DECREASE_QUANTITY) {

    // const quantityDecrease = action.quantityDecrease;
    // console.log(quantityDecrease)
    if (product.quantity === 1) {
      // console.log('hay 1 unidad')

      const remainingItems = (cartItems, product) =>
        cartItems.filter(
          cartItem => cartItem.cartItemId !== product.cartItemId
        );
      return remainingItems(cartItems, product);
    } else {
     
      return cartItems.map(item =>
        item.Productoid === product.Productoid
          ? { ...item, quantity: item.quantity - product.quantityDecrease }
          : item
      );
    }
  }

  if (action.type === DELETE_FROM_CART) {
    const remainingItems = (cartItems, product) =>
      cartItems.filter(cartItem => cartItem.Productoid !== product.Productoid);
    return remainingItems(cartItems, product);
  }

  if (action.type === DELETE_ALL_FROM_CART) {
    return cartItems.filter(item => {
      return false;
    });
  }

  return state;
};

export default cartReducer;

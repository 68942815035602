import { SET_TERCEROINFO, SET_TERCERO_PRECIONIVEL } from "../actions/terceroActions";

const initState = {
    terceroId: null,
    ultimaFactura: null,
    diasUltimaFactura: null,
    terceroNombre: null,
    precioNivel: 'Precio3'
};

const terceroReducer = (state = initState, action) => {

    if (action.type === SET_TERCEROINFO) {
        const terceroId = action.payload.terceroId;
        const ultimaFactura = action.payload.ultimaFactura;
        const diasUltimaFactura = action.payload.diasUltimaFactura;
        const terceroNombre = action.payload.terceroNombre;

        return {
            ...state,
            terceroId: terceroId,
            ultimaFactura: ultimaFactura,
            diasUltimaFactura: diasUltimaFactura,
            terceroNombre: terceroNombre
        };
    }

    if (action.type === SET_TERCERO_PRECIONIVEL) {
        // console.log(state);
        const cartData = action.payload.cartData;

        let precioNivel = 'Precio3';
        // console.log(cartData);

        ///Se totaliza en cada nivel de precio
        let total_precio1 = 0;
        let total_precio2 = 0;
        // let total_precio3 = 0;

        for (let i = 0; i < cartData.length; i++) {
            const item = cartData[i];

            total_precio1 += parseFloat(item.quantity) * parseFloat(item.Precio1);
            total_precio2 += parseFloat(item.quantity) * parseFloat(item.Precio2);
            // total_precio3 += parseFloat(item.quantity) * parseFloat(item.Precio3);
        }


        ///Quiere decir que es primera compra o excedio los 45 dias sin comprar
        if (state.ultimaFactura === null || state.diasUltimaFactura > 45) {

            // console.log('primera compra 1', total_precio1);
            // console.log('primera compra 2', total_precio2);

            ///Solo se mira el precio 1 y 2, ya que por defecto siempre es nivel 3.
            ///Si en precio 1 es mas de 1mm, entonces es precio 1 ya que es el mas bajo
            if (total_precio1 > 1000000)
                precioNivel = 'Precio1';
            else if (total_precio2 > 500000)
                precioNivel = 'Precio2';
            else
                precioNivel = 'Precio3';


        } else {
            ///Cuando el cliente ya ha comprado, y esta en recompra en menos de 45 días


            // console.log('re compra 1', total_precio1);
            // console.log('re compra 2', total_precio2);

            if (total_precio1 > 500000)
                precioNivel = 'Precio1';
            else if (total_precio2 > 300000)
                precioNivel = 'Precio2';
            else
                precioNivel = 'Precio3';
        }

        // console.log(precioNivel);

        return {
            ...state,
            precioNivel
        }
    }

    return state;
};

export default terceroReducer;
